import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from './card-general'
import { Check, CheckCircle } from 'react-feather'
import MadeInUSA from '../assets/icons/madeinusa'
import Button from './atoms/Button'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { navigate } from "gatsby"

const WhyUs = () => {
  const [claimingInProgress, setClaimingInProgress] = useState(false)

  const [offer, setOffer] = useLocalStorage('offers', {})

  const claimOffer = () => {
    setOffer({ offer: "$50 OFF Tesla Wall Charger Installation", expires: '' })
    setClaimingInProgress(true)
    setTimeout(() => {
      navigate("/contact/")
    }, 1000)
  }

  return (
    <StaticQuery
      query={graphql`
      query WhyUsListQuery {
        site {
          siteMetadata {
            whyUs
          }
        }
      }
    `}
      render={(data) => {


        return (
          <>
            <div className='rounded-2xl mb-2 shadow bg-brand-500 border-4 border-brand-600 -500'>
              <div class="px-8 pt-6"><h2 class="text-white text-xl md:text-2xl font-display">Why Us?</h2></div>
              <ul className="pb-8 pt-6 px-8 space-y-6 text-gray-600">
                <li className="flex items-start">
                  {' '}
                  <CheckCircle size={24} fontWeight="900" strokeWidth={3} className="text-brand-100 -500 shrink-0" />

                  <div className='ml-4'><span className='font-semibold font-display text-white block'>No Hidden Costs, Free Quotes</span>
                    <p className="text-brand-300">Transparent, Up Front Pricing Without Surprises</p></div>
                </li>


                <li className="flex items-start">
                  <CheckCircle size={24} fontWeight="900" strokeWidth={3} className="text-white shrink-0" />

                  <div className='ml-4'><span className='font-semibold font-display text-white block'>Family-Owned &amp; Operated</span>

                    <p className="text-brand-300">Sacramento-Based Contractors</p></div>
                </li>

                <li className="flex items-start">
                  <CheckCircle size={24} fontWeight="900" strokeWidth={3} className="text-white shrink-0" />

                  <div className='ml-4'><span className='font-semibold font-display text-white block'>Experienced</span>

                    <p className="text-brand-300">10+ Years of Experience</p>
                  </div>
                </li>

                <li className="flex items-start">
                  <CheckCircle size={24} fontWeight="900" strokeWidth={3} className="text-white shrink-0" />

                  <div className='ml-4'><span className='font-semibold font-display text-white block'>Satisfaction Guarantee</span>

                    <p className="text-brand-300">We Offer a Satisfaction Guarantee</p>
                  </div>
                </li>


              </ul>
            </div>

            <div className='rounded-2xl mb-2 shadow bg-white'>
              <div class="px-8 py-6 flex items-start space-x-4">

                <img src="https://images.ctfassets.net/seqn6hyrh7p7/1YBBOd4h58mVjDFPWklph0/0b0634904698a95f1b53f337ce548401/Screen_Shot_2022-08-04_at_12.48.30_PM.png?h=250" className='w-16 h-16 rounded-full border-2 border-accent-500' />
                <div>
                  <h3 class="text-brand-600 font-black text-lg md:text-xl font-display">Talk to our experts</h3>
                  <p className='text-brand-400 font-display font-normal'>Free estimates, clear straightforward pricing</p>
                  <Button className='bg-white mt-4' to="/contact/">Get Pricing</Button>
                </div>
              </div>
            </div>






          </>
        )
      }}
    />
  )
}

export default WhyUs
