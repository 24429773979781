import React from 'react'
import { Star } from 'react-feather'
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from '../consts'


function HeroRatingStars({ theme, centerAll, classes = "", alignLeftAlways }) {

  return (
    <div className={`${centerAll && 'md:text-center'} text-left ${theme == 'light' ? 'text-white' : 'text-gray-800'}`}>
      <div className={`mt-8 md:mt-12 font-semibold ${classes}`}>
        <div className={`flex items-center mb-2 ${centerAll ? 'justify-center' : alignLeftAlways ? 'justify-start' : 'justify-center md:justify-start'}`}>
          {new Array(5).fill('').map((item) => <Star fill={"#ffbc00"} stroke={'0'} />)}
        </div>
        <span
          itemScope
          className="rating-desc"
          itemType="https://schema.org/Product"
        >
          <span itemProp="name">LV's Iron Works</span>{' '}
          <span
            itemScope
            itemProp="aggregateRating"
            itemType="https://schema.org/AggregateRating"
          >
            rated <span itemProp="ratingValue">{REVIEW_AVG}</span>/5 <br />
            based on <span itemProp="reviewCount">
              {TOTAL_REVIEW_COUNT}
            </span>{' '}
            reviews.{' '}
          </span>
        </span>
      </div>
    </div>
  )

}


export default HeroRatingStars
